import debug from 'debug'
import { sendEventTag } from '../../../domain/analytics/ATInternetService'
import { omit } from '../../../utils/object'
import { configureTagEvent, TAG_TYPES } from './tagsV3.middlewares.utils'

const d = debug('clickTagsV3Middelware')

export const tagEvent = ({ action, getState }) => {
  const store = getState()
  const { configuredTag } = configureTagEvent({ action, store }, TAG_TYPES.CLICK)

  if (configuredTag) {
    d('sending click tag V3 ', configuredTag)
    const event_name = configuredTag.event_name ?? 'click.action'
    sendEventTag(event_name, omit(configuredTag, ['event_name']))
  }
}

const tagsV3Middlewares =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__BROWSER__) {
      try {
        tagEvent({ action, getState })
      } catch (error) {
        console.error(error)
      }
    }

    return r
  }

export default tagsV3Middlewares
