import { requestMove } from '../map/map.actions'
import { hideCompleteSuggest } from '../ui/ui.actions'

import { bboxArrayToBboxArrays } from '../../domain/map/conversion'
import { getViewportFromGeometry } from '../../domain/map/shape/map.polygon.utils'
import { projectDistrictZipcodeToWholeCityZipcode } from '../../domain/utils/location'
import {
  ROUTE_ADDRESS,
  ROUTE_QUIZ_CITIES_DEPARTMENT,
  ROUTE_QUIZ_CITIES_REGION,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_COUNTRY,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_REGION
} from '../../routes'
import { navigateTo } from '../history/history.actions'
import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { setNavigationDone } from '../history/historySlice'
import { selectSearchTerms } from '../search/search.selectors'
import { geocodeForwardCtx, geocodeLevelsWithContour, searchServiceCtx } from '../search/search.service.constants'
import { setSearchTerms } from '../search/searchSlice'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { hideUiElement, showUiElement } from '../ui/uiSlice'
import { searchAddressContour } from './address.actions'
import { selectAddressContour, selectFirstAddress } from './address.selectors'
import {
  addContourToFirstAddress,
  loadAddressError,
  loadAddressRequest,
  loadMultiAddressSuccess,
  loadSingleAddressSuccess,
  removeAddressError,
  resetAddress,
  resetDescription
} from './addressSlice'

export const handleSearchDisplayingForAddressMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadMultiAddressSuccess.type:
      case loadAddressError.type:
      case loadAddressRequest.type:
        dispatch(showUiElement(UI_ELEMENTS.SEARCH_DIALOG))
        break
      case loadSingleAddressSuccess.type:
        dispatch(hideUiElement(UI_ELEMENTS.SEARCH_DIALOG))
        break
    }

    return next(action)
  }

export const mapMoveOnAddressLoadingMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadMultiAddressSuccess.type:
      case loadSingleAddressSuccess.type:
        const payloadOptions = action?.payload?.options
        if (payloadOptions?.targetCenter) {
          const zoom = payloadOptions?.targetZoom ? { zoom: payloadOptions.targetZoom } : {}
          requestMove({ center: payloadOptions.targetCenter, options: { ...zoom, avoidAnimation: true } })(
            dispatch,
            getState
          )
          break
        } else {
          const payloadData = action?.payload?.data
          const firstAddress = payloadData?.addresses?.[0]
          if (
            __BROWSER__ &&
            action.type === loadSingleAddressSuccess.type &&
            geocodeLevelsWithContour.includes(firstAddress?.geocode_level)
          ) {
            break
          }

          if (searchServiceCtx.geocodeLevel.unnamed_road === firstAddress?.geocode_level) {
            const { lat, lng } = firstAddress?.coordinates ?? {}
            if (lat && lng) {
              requestMove({ center: [lng, lat], options: { zoom: 17, avoidAnimation: true } })(dispatch, getState)
              break
            }
          }

          const viewportOrBbox = payloadData?.viewport || firstAddress?.bbox
          if (viewportOrBbox) {
            requestMove({ bbox: bboxArrayToBboxArrays(viewportOrBbox), options: { avoidAnimation: true } })(
              dispatch,
              getState
            )
            break
          }
        }
        break
      case addContourToFirstAddress.type:
        requestMove({
          bbox: bboxArrayToBboxArrays(getViewportFromGeometry(action?.payload)),
          options: { avoidAnimation: true }
        })(dispatch, getState)
        break
    }

    return next(action)
  }

export const getRouteForAddress = address => {
  switch (address.geocode_level) {
    case searchServiceCtx.geocodeLevel.admin1:
      return ROUTE_SEO_REGION
    case searchServiceCtx.geocodeLevel.admin7:
      return ROUTE_SEO_DEPARTMENT
    case searchServiceCtx.geocodeLevel.country:
      return ROUTE_SEO_COUNTRY
    case searchServiceCtx.geocodeLevel.town:
    case searchServiceCtx.geocodeLevel.district:
      if (address.postcode && address.town) {
        return ROUTE_SEO_ADDRESS
      }
      return ROUTE_ADDRESS
    default:
      return ROUTE_ADDRESS
  }
}

export const navigateOnAddressMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    switch (action.type) {
      case loadSingleAddressSuccess.type:
        const address = selectFirstAddress(getState())
        if (__BROWSER__ && !selectSearchTerms(getState())) {
          const label = address?.label
          dispatch(setSearchTerms(label))
        }
        const options = action?.payload?.options ?? {}
        if (!options.avoidRedirect) {
          navigateTo({
            route: getRouteForAddress(address),
            selectedStoreState: { address },
            routeOptions: { avoidRefetchingPageData: true }
          })(dispatch, getState)
        }
        break
    }
    return r
  }

export const resetDescriptionAfterSearch =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadMultiAddressSuccess.type:
      case loadSingleAddressSuccess.type:
        dispatch(resetDescription())
        break
    }

    return next(action)
  }

export const handleRemoveError =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadAddressRequest.type:
        dispatch(removeAddressError())
        break
    }

    return next(action)
  }

export const hideSearchDialogOnSearchToShowPlaceholdersAsap =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadAddressRequest.type:
        hideCompleteSuggest()(dispatch, getState)
        break
    }

    return next(action)
  }

export const resetSearchDataOnLoadAddressRequest =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadAddressRequest.type:
        dispatch(resetAddress())
        break
    }
    return next(action)
  }

const PAGES_WITH_CONTOUR = [
  ROUTE_ADDRESS,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_REGION,
  ROUTE_QUIZ_CITIES_REGION,
  ROUTE_QUIZ_CITIES_DEPARTMENT
]

export const searchContourOnNavigationDone =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    if (action.type === setNavigationDone.type && PAGES_WITH_CONTOUR.includes(selectCurrentHistoryRoute(getState()))) {
      if (selectAddressContour(getState())) return

      const { postcode, town, label, geocode_level } = selectFirstAddress(getState())
      if (geocode_level === searchServiceCtx.geocodeLevel.town) {
        searchAddressContour(`${projectDistrictZipcodeToWholeCityZipcode(postcode)}-${town}`)(dispatch, getState)
      } else if (geocode_level === searchServiceCtx.geocodeLevel.district) {
        searchAddressContour(`${label}`)(dispatch, getState)
      } else if (geocode_level === searchServiceCtx.geocodeLevel.admin7) {
        searchAddressContour(`${label}`, geocodeForwardCtx.filter.county)(dispatch, getState)
      } else if (geocode_level === searchServiceCtx.geocodeLevel.admin1) {
        searchAddressContour(`${label}`, geocodeForwardCtx.filter.state)(dispatch, getState)
      }
    }
    return r
  }

export default [
  handleSearchDisplayingForAddressMiddleware,
  mapMoveOnAddressLoadingMiddleware,
  navigateOnAddressMiddleware,
  resetDescriptionAfterSearch,
  handleRemoveError,
  hideSearchDialogOnSearchToShowPlaceholdersAsap,
  resetSearchDataOnLoadAddressRequest,
  searchContourOnNavigationDone
]
