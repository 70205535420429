import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  h1title: undefined,
  seoTownDetails: undefined,
  arrivalCityDescription: '',
  seoActivitiesForDeparture: undefined,
  seoActivitiesForArrival: undefined,
  seoCitiesForActivities: undefined,
  seoCitiesForFooter: undefined,
  seoAppsForFooter: undefined,
  breadcrumbs: [],
  topSearchLinks: [],
  displayTopSearchLinks: false,
  citiesToDiscoverLinks: [],
  rubric: {},
  townFromPath: undefined,
  directoryPageIdx: undefined,
  directoryData: undefined,
  faqData: undefined,
  rubricLinks: {},
  currentRubric: undefined,
  quiz: {
    regions: []
  }
}

const seoSlice = createSlice({
  name: 'seo',
  initialState,
  reducers: {
    setSeoTownFromPath: (state, action) => {
      state.townFromPath = action.payload
    },
    setSeoTownDetails: (state, action) => {
      state.seoTownDetails = action.payload
    },
    setSeoArrivalCityDescription: (state, action) => {
      state.arrivalCityDescription = action.payload
    },
    setSeoActivitiesForSteps: (state, action) => {
      const { seoActivitiesForDeparture, seoActivitiesForArrival } = action.payload
      state.seoActivitiesForDeparture = seoActivitiesForDeparture
      state.seoActivitiesForArrival = seoActivitiesForArrival
    },
    setSeoCitiesForFooter: (state, action) => {
      state.seoCitiesForFooter = action.payload
    },
    setSeoAppsForFooter: (state, action) => {
      state.seoAppsForFooter = action.payload
    },
    setSeoActivities: (state, action) => {
      state.seoActivities = action.payload
    },
    setSeoPoisForCity: (state, action) => {
      const { pois, poisOvm } = action.payload
      state.pois = pois
      state.poisOvm = poisOvm
    },
    setSeoTopPois: (state, action) => {
      const { pois } = action.payload
      state.pois = pois
    },
    setSeoBreadcrumbLinks: (state, action) => {
      state.breadcrumbs = action.payload ?? []
    },
    setSeoTopSearchLinks: (state, action) => {
      const topSearchLinks = action?.payload ?? []
      state.topSearchLinks = topSearchLinks
      state.displayTopSearchLinks = topSearchLinks.length > 0 // we can’t use topSearchLinks.length because it’s flushed in browser __PRELOADED_STATE__
    },
    setSeoCitiesToDiscoverLinks: (state, action) => {
      state.citiesToDiscoverLinks = action?.payload ?? []
    },
    setSeoRubrics: (state, action) => {
      state.rubrics = action.payload
    },
    setSeoItineraryData: (state, action) => {
      state.itineraryData = action.payload
    },
    setSeoDirectoryPageIdx: (state, action) => {
      state.directoryPageIdx = action.payload
    },
    setSeoDirectoryData: (state, action) => {
      state.directoryData = action.payload
    },
    setSeoFaqData: (state, action) => {
      state.faqData = action.payload
    },
    setSeoRubricLinksForNamespace: (state, action) => {
      const { namespace, links } = action.payload
      state.rubricLinks[namespace] = links
    },
    setSeoH1Title: (state, action) => {
      state.h1title = action.payload
    },
    resetSeoH1Title: (state, action) => {
      state.h1title = undefined
    },
    setSeoCurrentRubric: (state, action) => {
      state.currentRubric = action.payload
    },
    setSeoRubricLabel: (state, action) => {
      state.rubric.label = action.payload
    },
    resetSeoRubricLabel: state => {
      state.rubric.label = undefined
    },
    setSeoParentRubric: (state, action) => {
      const { rubricId, rubricLabel } = action.payload
      state.rubric.parentId = rubricId
      state.rubric.parentLabel = rubricLabel
    },
    resetSeoParentRubric: state => {
      state.rubric.parentId = undefined
      state.rubric.parentLabel = undefined
    },
    setSeoSiblingsCityActivity: (state, action) => {
      state.siblingsCityActivity = action.payload
    }
  }
})

export const {
  setSeoTownFromPath,
  setSeoTownDetails,
  setSeoArrivalCityDescription,
  setSeoActivities,
  setSeoActivitiesForSteps,
  setSeoCitiesForActivities,
  setSeoCitiesForFooter,
  setSeoAppsForFooter,
  setSeoPoisForCity,
  setSeoTopPois,
  setSeoBreadcrumbLinks,
  resetSeoBreadcrumbLinks,
  setSeoTopSearchLinks,
  setSeoCitiesToDiscoverLinks,
  setSeoRubrics,
  setSeoCurrentRubric,
  setSeoItineraryData,
  setSeoDirectoryPageIdx,
  setSeoDirectoryData,
  setSeoFaqData,
  setSeoRubricLinksForNamespace,
  setSeoH1Title,
  resetSeoH1Title,
  setSeoRubricLabel,
  setSeoParentRubric,
  resetSeoRubricLabel,
  resetSeoParentRubric,
  setSeoSiblingsCityActivity
} = seoSlice.actions

export default seoSlice
