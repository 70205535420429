import { selectHasUserConsentedForTracking } from '../../store/cmp/cmp.selectors'
import { doAtInternetAction, getCookieDomain } from '../../domain/analytics/ATInternetService'
import { registerConsent } from '../cmp/cmpSlice'

export const handleConsent =
  ({ getState }) =>
  next =>
  action => {
    if (action.type === registerConsent.type) {
      const consentTrackingBefore = selectHasUserConsentedForTracking(getState())
      const r = next(action)
      const consentTrackingAfter = selectHasUserConsentedForTracking(getState())

      if (!consentTrackingBefore && consentTrackingAfter) {
        doAtInternetAction(tag => {
          global.ATInternet.Utils.consentReceived(true)
          tag.clientSideUserId.store()
        })
      } else if (consentTrackingBefore && !consentTrackingAfter) {
        global.ATInternet.Utils.consentReceived(false)
        document.cookie = `atuserid=;expires=Thu, 1 Jan 1970 00:00:01 GMT;domain=${getCookieDomain(location.host)}`
      }

      return r
    }

    return next(action)
  }

export default handleConsent
