import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { isGE, isPE } from './domain/Device'
import SentryService from './domain/error/SentryService'
import RouterService from './domain/router/RouterService'
import createHistory from './domain/router/createHistory'
import { extractQueryStringParams, formatFullRouteFromHistory, generateFullRoute } from './domain/router/routeUtils'
import { ONE_SECOND_MS } from './domain/utils/date'
import request from './domain/utils/request'
import { containsOtherThanXtorHash } from './domain/utils/url'
import { getService } from './parameters'
import { ROUTE_HOME } from './routes'
import SERVICES from './services.constants'
import { loadAndEnrollAbTest } from './store/abtest/abtest.actions'
import createStore from './store/createStore'
import { navigateTo, registerRoute } from './store/history/history.actions'
import { loadMapEvents } from './store/map/map.actions'
import { selectGeoipEnabled } from './store/map/map.selectors'
import { incrementVisitCount, setDevice } from './store/navigation/navigationSlice'
import syncStoreWithBrowserStorage from './store/syncStoreWithBrowserStorage'
import getClientState from './utils/clientState'

const history = createHistory()

const store = createStore({ preloadedState: getClientState(window.__PRELOADED_STATE__), history })

delete window.__PRELOADED_STATE__

global.__mappy__store__rare_usage = store

store.dispatch(setDevice(isPE() ? 'pe' : 'ge'))

const fullRoute = formatFullRouteFromHistory(history)
const routeParams = extractQueryStringParams(history)

registerRoute(fullRoute, routeParams)(store.dispatch, store.getState)

store.dispatch(incrementVisitCount())

syncStoreWithBrowserStorage(store)

// A/B test loading as soon as possible, to enroll variants
loadAndEnrollAbTest()(store.dispatch, store.getState)

loadMapEvents()(store.dispatch, store.getState)

const routerService = new RouterService({ history, store })

const redirectToHP = () => {
  navigateTo({
    route: ROUTE_HOME
  })(store.dispatch, store.getState)
  return routerService.resolveRoute({
    fullRoute: generateFullRoute(ROUTE_HOME)
  })
}

const requestGeoIp = () =>
  Promise.resolve().then(() => {
    if (selectGeoipEnabled(store.getState()) && isGE()) {
      return request
        .get(getService(SERVICES.WEB_GEOIP, location.search), { timeout: 5 * ONE_SECOND_MS })
        .then(resp => (window.GEOIP = resp.data))
        .catch(e => console.error('geoip fetch error', e))
    }
  })
requestGeoIp()

const doesOnboardingRouteContainsHashExcludingXtor = containsOtherThanXtorHash(window.location.hash)

routerService
  .resolveCurrentRoute({
    state: {
      avoidDispatchingAnyPageAction: !doesOnboardingRouteContainsHashExcludingXtor
    }
  })
  .catch(e => {
    SentryService.captureException(e)
    return redirectToHP()
  })
  .then(currentPageComponent => {
    const container = document.getElementById('app')
    const root = createRoot(container)
    root.render(<App {...{ store, currentPageComponent, routerService }} />)
  })

if ('serviceWorker' in navigator) {
  window.navigator.serviceWorker
    .register('/serviceworker.js', {
      scope: '/'
    })
    .catch(function (e) {
      // Fail silently (some browser, like Firefox, throws a security error on some security settings)
      console.log('SW: registration failure', e)
    })
}

console.log(`
                             .-////-
                        .-/+oooooooo/
                   .-/+oooooooooooooo-
              .-:++oooooooooooooooooo:   Rejoignez-nous
         .-:+++oooooooooooooooooooooo+
     -:/++++++oooooooooooooooooooooooo    pour rendre Mappy
   :++++++++++oooooooooooooooooooooooo-
  .+++++++++++oooooooooooooooooooooooo/    plus rapide,
   ++++++++++oooooooooooooooooooooooooo
    :++++++++oooooooooooooooooooooooooo.    plus pratique,
       ..-:/++++++++ooooooooooooooooooo:
              .--://++++ooooooooooooooo/     plus offline,
                    .-/+++oooooooooooooo
                       ./++ooooooooooooo.     plus PWA !
                         ./++ooooooooooo:
                           .:+oooooooooo+
                             .:+oooooooo+
                                :+ooooo/
                                  .---

 https://www.welcometothejungle.com/fr/companies/ratp-smart-systems
 `)
