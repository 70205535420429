import { PUB_TAG } from '../../domain/analytics/PublisherTagsService'
import { getDistanceValue } from '../../domain/analytics/analyticsUtils'
import { selectItineraryState } from '../itinerary/itinerary.selectors'
import { getProviderMode } from '../itinerary/itinerary.utils'
import { setCurrentProvider, setItineraryIsComplete } from '../itinerary/itinerarySlice'
import { selectCurrentItineraryRoute, selectRoutesForMonomode } from '../itinerary/routes.selectors'
import { TAG_MULTIPATH_CTA, TAG_PUBLISHER } from './analytics.actionTypes'

const getMultipathPredefinedTagInfo =
  ({ provider, ...otherTagInfo }) =>
  getState => {
    const store = getState()

    const { itinerary } = store
    const currentProvider = provider || itinerary.currentProvider
    const currentRoute = selectCurrentItineraryRoute(store)
    const length = currentRoute?.length?.value
    const distance = getDistanceValue(length)

    return {
      provider: currentProvider,
      mode: getProviderMode({
        ...itinerary,
        currentProvider
      }),
      distance,
      selectionslider: getProviderMode(itinerary),
      ...otherTagInfo
    }
  }

const getAllMultipathRoutePublisherTagInfosForImpression = () => getState => {
  const currentRoutesByProvider = selectRoutesForMonomode(getState())

  return Object.keys(currentRoutesByProvider).map((providerRoute, idx) => {
    const tag = idx === 0 ? PUB_TAG.PUB_MULTIPATH_RESULTAT_TOP : PUB_TAG.PUB_MULTIPATH_RESULTAT
    const provider = currentRoutesByProvider?.[providerRoute]?.[0]?.provider

    return getMultipathPredefinedTagInfo({
      tag,
      provider
    })(getState)
  })
}

/**
 * This functions allows us to send all impression tags
 * PUB_MULTIPATH_RESULTAT_TOP, PUB_MULTIPATH_RESULTAT and PUB_MULTIPATH_RESULTAT in batch.
 *
 * Sending of single impression/click tag is handled in sendPublisherMultipathTag()
 */
const sendAllMultipathRoutePublisherImpressionTags = () => (dispatch, getState) => {
  const allTagInfosForImpression = getAllMultipathRoutePublisherTagInfosForImpression()(getState) || []

  allTagInfosForImpression.forEach(preparedTag =>
    sendPublisherMultipathTag({ isImpression: true, tagInfo: preparedTag })(dispatch, getState)
  )
}

/**
 * This function receives mainly a tag and provider and  build predefined tag informations for multipath
 * then it delegates the rest to generic publishTags.middleware (which given some tagInfo, it fomats the tag for the analytics lib and send it )
 * It is currently used for handling:
 * - CTA impression and clicks tags through <TagActionButton /> or <TagActionLink />
 * - all multipath route related click tags (impression is handled in batch in sendAllMultipathRoutePublisherImpressionTags())
 */
const sendPublisherMultipathTag =
  ({ isImpression, tagInfo }) =>
  (dispatch, getState) => {
    const multipathPredefinedTagInfo = getMultipathPredefinedTagInfo(tagInfo)(getState)

    if (!multipathPredefinedTagInfo) return

    dispatch({
      type: TAG_PUBLISHER,
      isImpression: Boolean(isImpression),
      tagInfo: {
        ...tagInfo,
        ...multipathPredefinedTagInfo
      }
    })
  }

export const multipathPublisherTagsMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const prevState = getState()
    const r = next(action)

    if (__BROWSER__) {
      try {
        switch (action.type) {
          case TAG_MULTIPATH_CTA: {
            const { isImpression, tagInfo } = action

            sendPublisherMultipathTag({ isImpression, tagInfo })(dispatch, getState)

            break
          }
          case setItineraryIsComplete.type: {
            sendAllMultipathRoutePublisherImpressionTags()(dispatch, getState)

            break
          }
          case setCurrentProvider.type: {
            const prevMode = getProviderMode(prevState.itinerary)
            const nextMode = getProviderMode(selectItineraryState(getState()))

            if (prevMode !== nextMode) {
              sendAllMultipathRoutePublisherImpressionTags()(dispatch, getState)
            }

            break
          }
        }
      } catch (error) {
        console.error(error)
      }
    }

    return r
  }
