import debug from 'debug'
import { sendEventTag } from '../../../domain/analytics/ATInternetService'
import { ROUTE_ITINERARY_COMPUTING, ROUTE_ITINERARY_RESULTS_BY_PROVIDER } from '../../../routes'
import {
  selectCurrentHistoryFullRoute,
  selectCurrentHistoryRoute,
  selectIsPreviousHistoryRoutePageless,
  selectShouldAvoidSendPageTag
} from '../../history/history.selectors'
import { setNavigationDone } from '../../history/historySlice'
import { dispatchTagPageV3 } from '../analytics.actions'
import { SEND_PAGE_TAG as SEND_PAGE_TAG_V3 } from '../analytics.actionTypes'
import { configureTagEvent, TAG_TYPES } from './tagsV3.middlewares.utils'

const d = debug('clickTagsV3Middelware')

const tagPage = ({ getState, action }) => {
  const store = getState()
  const route = selectCurrentHistoryRoute(store)
  const fullRoute = selectCurrentHistoryFullRoute(store)
  const { configuredTag } = configureTagEvent({ action, store, route }, TAG_TYPES.PAGE)

  if (
    route === ROUTE_ITINERARY_RESULTS_BY_PROVIDER ||
    route === ROUTE_ITINERARY_COMPUTING ||
    selectShouldAvoidSendPageTag(store)
  ) {
    // do not send tag page for navigation with special attribut or
    // for "calculation" routes because they don't have physically page.
    return d(`skipping tagging for ${fullRoute}`)
  }

  if (!configuredTag) return d(`No tag have been defined for ${fullRoute}`)

  if (configuredTag) {
    d('sending page tag V3 ', configuredTag)
    const event_name = configuredTag.event_name ?? 'affichage.page'
    sendEventTag(event_name, configuredTag)
  }
}

export const pageTagsMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)
    if (__BROWSER__) {
      switch (action.type) {
        case SEND_PAGE_TAG_V3:
          if (selectIsPreviousHistoryRoutePageless(getState())) return r

          d('SEND_PAGE_TAG_V3 for route', selectCurrentHistoryRoute(getState()))
          try {
            tagPage({ getState, action })
          } catch (error) {
            console.error(error)
          }
      }
    }

    return r
  }

export const sendPageTagOnNavigationDone =
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === setNavigationDone.type) {
      dispatchTagPageV3(action)(dispatch)
    }

    return next(action)
  }

export default [pageTagsMiddleware, sendPageTagOnNavigationDone]
