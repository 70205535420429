import { LOCALES } from './domain/i18n'
import { getServerServiceUrl } from './parameters.server'
import { COUNTRIES_CODES } from './routes/address/Countries'
import SERVICES from './services.constants'

const { fr_FR, fr_BE, en_GB, nl_BE } = LOCALES

export function getDomain() {
  return process?.env?.DOMAIN || global?.DOMAIN || 'mappy'
}

export const getBonjourDomain = () => {
  switch (getDomain()) {
    case 'mappysnap':
      return 'staging'
    case 'mappyrecette':
      return 'preprod'
    default:
      return 'prod'
  }
}

export const isProduction = () => getDomain() === 'mappy'

export const isRecette = () => getDomain() === 'mappyrecette'

export const isDevelopment = () => ['mappydev', 'mappysnap'].includes(getDomain())

export const getApiKey = () => 'f2wjQp1eFdTe26YcAP3K92m7d9cV8x1Z'

export const getApiKeyHeader = () => ({ apikey: getApiKey() })

const BROWSER_URLS = {
  [SERVICES.MAP]: { baseUrl: `https://api-map.${getDomain()}.net/map`, ...getApiKeyHeader() },
  [SERVICES.MULTIPATH]: { baseUrl: `https://api-iti.${getDomain()}.net/multipath/7.0`, ...getApiKeyHeader() },
  [SERVICES.GEOENTITY]: { baseUrl: `https://api-poi.${getDomain()}.net/data/poi/5.3`, ...getApiKeyHeader() },
  [SERVICES.GEOENTITY_V2]: {
    baseUrl: `https://api-poi.${getDomain()}.net/api/v2`,
    ...getApiKeyHeader()
  },
  [SERVICES.GEOCODE_REVERSE]: {
    baseUrl: `https://api-geocode.${getDomain()}.net/reverse/1.0/find`,
    ...getApiKeyHeader()
  },
  [SERVICES.GEOCODE_FORWARD]: {
    baseUrl: `https://api-geocode.${getDomain()}.net/forward/1.0/find`,
    ...getApiKeyHeader()
  },
  [SERVICES.SEARCH]: { baseUrl: `https://api-search.${getDomain()}.net/search/1.1/find`, ...getApiKeyHeader() },
  [SERVICES.SUGGEST]: { baseUrl: `https://api-suggest.${getDomain()}.net/suggest/1.3/suggest`, ...getApiKeyHeader() },
  [SERVICES.POIASSETS]: { baseUrl: `https://poiassets.${getDomain()}.net` },
  [SERVICES.JOURNEY]: { baseUrl: `https://api-poi.${getDomain()}.net/data/poi/5.3/journey`, ...getApiKeyHeader() },
  [SERVICES.PANO]: { baseUrl: `https://api-poi.${getDomain()}.net/data/pano/1.10`, ...getApiKeyHeader() },
  [SERVICES.BUC]: { baseUrl: `https://log.${getDomain()}.net/log/1.0/log/buc` },
  [SERVICES.WEB_GEOIP]: { baseUrl: `/front-services/geoip` },
  [SERVICES.WEB_SEO_DESCRIPTION]: { baseUrl: '/front-services/description' },
  [SERVICES.WEB_SEO_FAQ]: { baseUrl: '/front-services/faq' },
  [SERVICES.WEB_SEO_IMAGE]: { baseUrl: '/front-services/image' },
  [SERVICES.WEB_SEO_LINKS]: { baseUrl: '/front-services/links' },
  [SERVICES.WEB_QUIZ]: { baseUrl: '/front-services/quiz' },
  [SERVICES.WEATHER]: { baseUrl: '/front-services/weather' },
  [SERVICES.WEB_VERSION]: { baseUrl: '/front-services/version' },
  [SERVICES.KEYCLOAK]: { baseUrl: `https://passport.${getDomain()}.com/auth/` },
  [SERVICES.USER_ACCOUNT]: { baseUrl: `https://maats.${getDomain()}.net/api/v2/user` },
  [SERVICES.CONSENT]: { baseUrl: `https://log.${getDomain()}.net/log/1.0/consent` },
  [SERVICES.CONSENT_REFUSE]: { baseUrl: `https://log.${getDomain()}.net/log/1.0/consent-refuse` },
  [SERVICES.ABTEST]: { baseUrl: `https://ab.${getDomain()}.net` },
  [SERVICES.AT_LIB]: { baseUrl: `https://tag.aticdn.net/${isProduction() ? '509857' : '509858'}/smarttag.js` },
  [SERVICES.MAP_EVENT]: {
    baseUrl: `https://map-event.${getBonjourDomain()}.bonjour-ratp.fr/api/1.0/mappy/events`
  }
}

export function getService(key, path = '', forceClientUrl = false) {
  if (__SERVER__ && !forceClientUrl) {
    const service = getServerServiceUrl(key, path)
    if (service) return service
  }

  const { baseUrl, ...otherKeys } = BROWSER_URLS[key]
  return { url: `${baseUrl}${path}`, ...otherKeys }
}

export function getServiceParameters(key, locale = fr_FR) {
  const languageParams = {
    [fr_FR]: {
      language: 'FRE'
    },
    [fr_BE]: {
      language: 'FRE'
    },
    [nl_BE]: {
      language: 'DUT'
    },
    [en_GB]: {
      language: 'ENG'
    }
  }

  const parameters = {
    [SERVICES.SEARCH]: {
      [fr_FR]: {
        language: 'FRE',
        favorite_country: COUNTRIES_CODES.FRANCE
      },
      [fr_BE]: {
        language: 'FRE',
        favorite_country: COUNTRIES_CODES.BELGIUM
      },
      [nl_BE]: {
        language: 'DUT',
        favorite_country: COUNTRIES_CODES.BELGIUM
      },
      [en_GB]: {
        language: 'ENG',
        favorite_country: COUNTRIES_CODES.UNITED_KINGDOM
      }
    },
    [SERVICES.SUGGEST]: {
      [fr_FR]: {
        language: 'FRE'
      },
      [fr_BE]: {
        language: 'FRE'
      },
      [nl_BE]: {
        language: 'ENG'
      },
      [en_GB]: {
        language: 'ENG'
      }
    },
    [SERVICES.GEOCODE_FORWARD]: languageParams,
    [SERVICES.GEOCODE_REVERSE]: languageParams
  }
  return parameters?.[key]?.[locale] ?? {}
}

export const getSvgIconUrl = (timestamp, icon) =>
  getService(SERVICES.POIASSETS, `/assets/${timestamp}/misc/svg/${icon}.svg`, true)?.url ?? ''

export function getDefaults() {
  return {
    [fr_FR]: {
      bbox: [
        [-5.141593, 41.33367],
        [9.559831, 51.088771]
      ],
      xtsite: isProduction() ? 509857 : 509858,
      countryCode: 250,
      countryLabel: 'France'
    },
    [fr_BE]: {
      bbox: [
        [0.541643, 49.111579],
        [8.358415, 52.279869]
      ],
      xtsite: isProduction() ? 549927 : 549923,
      countryCode: 56,
      countryLabel: 'Belgique'
    },
    [nl_BE]: {
      bbox: [
        [0.541643, 49.111579],
        [8.358415, 52.279869]
      ],
      xtsite: isProduction() ? 549928 : 549922,
      countryCode: 56,
      countryLabel: 'België'
    },
    [en_GB]: {
      bbox: [
        [-12.935437, 49.079093],
        [6.268663, 59.555068]
      ],
      xtsite: isProduction() ? 549925 : 549924,
      countryCode: 826,
      countryLabel: 'United-Kingdom'
    }
  }
}

export const npsLinks = {
  poi_card_mobile: 'https://form.typeform.com/to/YuiAYbMd?utm_source=WebRechMob',
  poi_card_desktop: 'https://form.typeform.com/to/YuiAYbMd?utm_source=WebRechFixe',
  itinerary_results_mobile: 'https://form.typeform.com/to/YuiAYbMd?utm_source=WebItiMob',
  itinerary_results_desktop: 'https://form.typeform.com/to/YuiAYbMd?utm_source=WebItiFixe'
}

export const BOOKING_SEARCH_EXTENSION_AID_DESKTOP = '1555664'
export const BOOKING_SEARCH_EXTENSION_AID_MOBILE = '1549052'

export const BOOKING_SEARCH_EXTENSION_LABEL_DESKTOP = 'see-cob-304142'
export const BOOKING_SEARCH_EXTENSION_LABEL_MOBILE = 'mobsbsearch-see-1549052'

export const BOOKING_SEARCH_EXTENSION_LABEL_DESKTOP_LP = 'pageseo-see-2427763-click'
export const BOOKING_SEARCH_EXTENSION_LABEL_MOBILE_LP = 'mobseo-see-2427762-click '

export const GEOENTITY_PARTNER_REDIRECT_PARAMS = {
  GE: {
    'booking-aid': '324859',
    'accor-merchantid': 'RT-FR018152-',
    epi2: 'desk',
    'pjdoc-xtor': '371'
  },
  PE: {
    'booking-aid': '343652',
    'accor-merchantid': 'RT-FR018152-',
    epi2: 'mobi',
    'pjdoc-xtor': '371'
  }
}

export const APPS_ADJUST_URL = 'https://app.adjust.com/g7ex60t'
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.mappy.app'
export const APPLE_STORE_URL = 'https://itunes.apple.com/fr/app/mappy-plan-itin%C3%A9raire-gps/id313834655'

export const getCountryBBoxForLocale = locale => {
  const defaultParameters = getDefaults()

  return defaultParameters[locale]?.bbox || defaultParameters['fr-FR'].bbox
}

export const getCountryCodesForLocale = locale => {
  const defaultParameters = getDefaults()

  return {
    countryCode: defaultParameters[locale]?.countryCode || defaultParameters['fr-FR'].countryCode,
    countryLabel: defaultParameters[locale]?.countryLabel || defaultParameters['fr-FR'].countryLabel
  }
}

export const getAirshipConfiguration = () => {
  const websitePushIdDev = 'web.com.mappy.mappydev'
  if (__DEV__)
    return {
      validPublicKey: 'BDoBHO9aTF7-i1G3A3nK-sA-6alqEuXydVHgY85nbXwBoeGIz7K1oEvaTjPPTGxsqC4u_fbHnSEBMyNR-liJjyI=',
      websitePushId: websitePushIdDev,
      appKey: '2WHKcPVbQLyC2ga8h11swQ',
      token: 'MToyV0hLY1BWYlFMeUMyZ2E4aDExc3dROmswZEVHWGV6OHFkRmVkcV9PWDFrMVg0WnNjOWx2UnZYQUh1bl9VRkFwdkE',
      baseDomain: 'https://fr.mappydev.com'
    }
  const domain = getDomain()
  const baseDomain = `https://fr.${domain}.com`
  switch (domain) {
    case 'mappysnap':
      return {
        validPublicKey: 'BKceIzjCqZfM9qZ4E-_315xxgln6mMEh_H9cHl__J-IuNWUaujYs_6EXnJoK0FDR28T6bPb7lT9UDXahVTd1MbM=',
        websitePushId: websitePushIdDev,
        appKey: 'pvhYHsWpRLCK92g9JRDElA',
        token: 'MTpwdmhZSHNXcFJMQ0s5Mmc5SlJERWxBOlhOcjAtV25Td3lYWXc3Q2FRbERxRFJqUWhmOUdHVWNnRVhnRmU2dWFxenM',
        baseDomain
      }
    case 'mappyrecette':
      return {
        validPublicKey: 'BLG93sYIFSCoRVXwZ6fjS1rvNKxtPOErDDoY4xZ3G2EkIE5J4oQn7m9py7yTVgN6Q10dyB2nk1DOoSQyIypUKyI=',
        websitePushId: websitePushIdDev,
        appKey: 'hD_UVCBVTfaGA1Y5sU6z4g',
        token: 'MTpoRF9VVkNCVlRmYUdBMVk1c1U2ejRnOlIwVExZUWRNZEVEWXQzZTJJaXQyem4yNnNMZ1dlU05VTmo5VTdfWGhRTXM',
        baseDomain
      }
    case 'mappy':
    default:
      return {
        validPublicKey: 'BNSFyUYxbGx9y5zm7OuI9UsYuzdQSaLfC_SeFDkryMgvNt9GvA4ELkaYabI8S0KOsfVu-9rhvMviqokdCrEjCfQ=',
        websitePushId: 'web.com.mappy.mappy',
        appKey: '-T5aKrEwQtm6HaL1E6o_hQ',
        token: 'MTotVDVhS3JFd1F0bTZIYUwxRTZvX2hROlRJY1BKeDRjdHp3aFdjYUREaG5ob3lJUklDZUlkai05SmtWb0o3ZGNGNnM',
        baseDomain
      }
  }
}

export const MINIMAL_NB_OF_POI_TO_OPEN_CITY = 400
