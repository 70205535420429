import { isPE } from '../../domain/Device'
import { bboxIsValid } from '../../domain/map/map.utils'
import { convertBboxToWestSouthEastNorthBbox, getBboxFromLngLatPoints } from '../../domain/utils/map'
import { isEmpty } from '../../utils/lang'
import { omitBy } from '../../utils/object'
import { selectIsFrenchLanguage, selectLocale } from '../navigation/navigation.selectors'
import { parseMapEvents } from './map.dataParser'
import { requestMapEvents } from './map.request'
import { selectAllMapEvents, selectIsMapReady, selectMapTargetData } from './map.selectors'

import { requestMapMove, requestMapZoom, setDefaultBbox, setMapEvents, setMapEventsPopin } from './mapSlice'

export const requestMove =
  ({ bbox, center, options }) =>
  (dispatch, getState) => {
    if (__SERVER__ && !bbox) return
    if (bbox && !bboxIsValid(bbox)) return

    const payload = omitBy(
      {
        bbox: bbox ? convertBboxToWestSouthEastNorthBbox(bbox) : undefined,
        center,
        options: !selectIsMapReady(getState()) ? { ...options, avoidAnimation: true } : options
      },
      isEmpty
    )

    __BROWSER__ ? dispatch(requestMapMove(payload)) : dispatch(setDefaultBbox(payload))
  }

export const fitAddresses =
  (addresses = []) =>
  (dispatch, getState) => {
    if (addresses.length === 0) return
    if (addresses.length === 1) {
      const bbox = addresses?.[0]?.bbox ?? []
      if (!bbox) return
      dispatch(requestMapMove({ bbox }))
    } else {
      fitPoints(addresses.map(({ coordinates }) => coordinates))(dispatch, getState)
    }
  }

const fitPoints = points => (dispatch, getState) => {
  dispatch(
    requestMapMove({
      bbox: getBboxFromLngLatPoints(points),
      options: {
        withFastAnimation: isPE()
      }
    })
  )
}

export const requestZoom =
  ({ zoom }) =>
  (dispatch, getState) => {
    if (!zoom) return
    if (zoom === selectMapTargetData(getState())?.targetZoom) return
    dispatch(requestMapZoom({ zoom }))
  }

export const loadMapEvents = () => (dispatch, getState) => {
  const locale = selectLocale(getState())
  const mapEvents = selectAllMapEvents(getState())
  if (!mapEvents.length)
    return requestMapEvents(locale).then(response => {
      dispatch(setMapEvents(parseMapEvents(response)))
    })
  return Promise.resolve()
}

export const setMapEventPopin = popinInformation => (dispatch, getState) => {
  const isFR = selectIsFrenchLanguage(getState())
  dispatch(
    setMapEventsPopin({
      title: isFR ? popinInformation.title_fr : popinInformation.title_en,
      description: isFR ? popinInformation.description_fr : popinInformation.description_en,
      asset_zone: popinInformation.asset_map_zone,
      asset_event: popinInformation.asset_event_title
    })
  )
}
