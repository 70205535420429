import { ROUTE_SUGGEST, ROUTE_TOOLS_COMPUTE_RADIUS } from '../../routes'
import { isEmpty } from '../../utils/lang'
import { updateCurrentSearchFromLngLat } from '../address/address.computeRadius.actions'
import { selectFirstAddress } from '../address/address.selectors'
import { loadSingleAddressComputeRadiusSuccess } from '../address/addressSlice'
import { dispatchTag } from '../analytics/analytics.actions'
import { TAG_CLICK_COMPUTE_RADIUS } from '../analytics/analytics.actionTypes'
import { selectGeolocationPosition, selectGeolocationStatus } from '../geolocation/geolocation.selectors'
import { setGeolocationPosition, setGeolocationStatus } from '../geolocation/geolocationSlice'
import { navigateBack } from '../history/history.actions'
import { selectCurrentHistoryRoute, selectPreviousHistoryRoute } from '../history/history.selectors'
import { pushHistory } from '../history/historySlice'
import { requestMove } from '../map/map.actions'
import { GEOLOC_STATUS_ACTIVE, GEOLOC_STATUS_FOLLOW } from '../map/map.constants'
import { selectIsMapInitialized } from '../map/map.selectors'
import { setMapStatus } from '../map/mapSlice'
import { setSearchTerms } from '../search/searchSlice'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { hideUiElement } from '../ui/uiSlice'
import { selectComputePosition } from './tools.selectors'
import { setComputeRadiusPosition } from './toolsSlice'

export const setGeoLocPositionInComputeRadiusPositionMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadSingleAddressComputeRadiusSuccess.type:
        if (
          !isEmpty(selectComputePosition(getState())) &&
          selectGeolocationStatus(getState()) === GEOLOC_STATUS_FOLLOW
        ) {
          dispatch(setGeolocationStatus(GEOLOC_STATUS_ACTIVE))
        }
        const addresses = action.payload?.data?.addresses
        const coordinates = addresses[0]?.coordinates
        dispatch(setComputeRadiusPosition({ position: coordinates }))
        requestMove({ center: coordinates, options: { zoom: 10 } })(dispatch, getState)
        break
    }

    return next(action)
  }

export const moveToGeolocPositionIfComputeRadiusPageMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case setGeolocationPosition.type:
      case pushHistory.type:
      case setMapStatus.type:
        const store = getState()
        const currentRoute = selectCurrentHistoryRoute(store)
        if (currentRoute !== ROUTE_TOOLS_COMPUTE_RADIUS || selectComputePosition(store)) break
        const geoLoc = selectGeolocationPosition(store)
        if (selectIsMapInitialized(store) && geoLoc) {
          updateCurrentSearchFromLngLat(geoLoc)(dispatch, getState)
        }
        break
    }
    return r
  }

export const handleActionWhenLoadSingleAddressComputeRadiusMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case loadSingleAddressComputeRadiusSuccess.type:
        dispatch(hideUiElement(UI_ELEMENTS.SEARCH_DIALOG))
        const { label } = selectFirstAddress(getState())
        dispatch(setSearchTerms(label))
        dispatchTag({
          type: TAG_CLICK_COMPUTE_RADIUS,
          tagInfo: {
            action: 'Adresse'
          }
        })(dispatch, getState)
        break
    }

    return r
  }

export const handleBackFromSuggestToComputeRadius =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (
      action.type === loadSingleAddressComputeRadiusSuccess.type &&
      selectCurrentHistoryRoute(getState()) === ROUTE_SUGGEST &&
      selectPreviousHistoryRoute(getState()) === ROUTE_TOOLS_COMPUTE_RADIUS
    ) {
      navigateBack()(dispatch, getState)
    }

    return r
  }

export const launchGeocodeReverseOnMarkerDrag =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    const { type, payload } = action
    if (type === setComputeRadiusPosition.type) {
      const { position, fromMarker } = payload
      if (fromMarker) {
        updateCurrentSearchFromLngLat(position)(dispatch, getState)
      }
    }
    return r
  }

export default [
  setGeoLocPositionInComputeRadiusPositionMiddleware,
  moveToGeolocPositionIfComputeRadiusPageMiddleware,
  handleActionWhenLoadSingleAddressComputeRadiusMiddleware,
  handleBackFromSuggestToComputeRadius,
  launchGeocodeReverseOnMarkerDrag
]
