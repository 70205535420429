import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TAG_GOTO_AJOUT_ETAPE } from '../../../store/analytics/analytics.actionTypes'
import { selectGeoentityRubricId } from '../../../store/geoentity/geoentity.selectors'
import { selectIsItineraryUniverse } from '../../../store/history/history.selectors'
import { setStepFromAddress } from '../../../store/itinerary/itinerary.actions'
import { addStep, setStepIdx } from '../../../store/itinerary/itinerarySlice'
import { PAGE_POI_NAME } from '../../analytics/PageTagsService'
import RectangleActionButton, {
  COLOR_GREY_EMPTY,
  SIZE_NORMAL
} from '../../genericComponents/buttons/RectangleActionButton'
import { useAction, withEverySelectorTruthy } from '../../utils/react-redux'
import useEventCallback from '../../utils/useEventCallback'
import addStepIcon from './add-step.svg'
import { selectAllSteps, selectIsMaxStepNumberReached } from './steps.selectors'

const AddPoiStepButton = ({ location }) => {
  const allSteps = useSelector(selectAllSteps)
  const rubricId = useSelector(selectGeoentityRubricId)
  const isMaxStepNumberReached = useSelector(selectIsMaxStepNumberReached)

  const dispatch = useDispatch()
  const execSetStepFromAddress = useAction(setStepFromAddress)

  const onClick = useEventCallback(() => {
    if (!isMaxStepNumberReached) dispatch(addStep())
    dispatch(setStepIdx(allSteps.length - (isMaxStepNumberReached ? 2 : 1)))
    execSetStepFromAddress(location)
  }, [allSteps, execSetStepFromAddress])

  return (
    <RectangleActionButton
      label={'itinerary.step.button.poi'}
      iconUrl={addStepIcon}
      color={COLOR_GREY_EMPTY}
      size={SIZE_NORMAL}
      onClick={onClick}
      tagData={{
        type: TAG_GOTO_AJOUT_ETAPE,
        tagInfo: {
          ancrage: PAGE_POI_NAME,
          catId: rubricId
        }
      }}
    />
  )
}

export default withEverySelectorTruthy(selectIsItineraryUniverse)(AddPoiStepButton)
