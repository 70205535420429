import { sendPageTag } from '../../domain/analytics/ATInternetService'
import { PAGE_TAG, resolvePageTag } from '../../domain/analytics/PageTagsService'
import { UI_ELEMENTS, USER_SCREENS } from '../ui/ui.constants'
import { isUIElementDisplayed, selectUserUIScreen } from '../ui/ui.selectors'
import { setUiUserScreen, showUiElement } from '../ui/uiSlice'
import { selectIsUserConnected } from '../userAccount/userAccount.selectors'

export const getPageTagForScreen = (storeState, screenName) => {
  switch (screenName) {
    case USER_SCREENS.default:
      return selectIsUserConnected(storeState) ? PAGE_TAG.PAGE_COMPTE_CONNECTE : PAGE_TAG.PAGE_COMPTE_NON_CONNECTE
    case USER_SCREENS.favorites:
      return PAGE_TAG.PAGE_COMPTE_LIEUXFAVORIS
    case USER_SCREENS.itineraryOptions:
      return PAGE_TAG.PAGE_COMPTE_OPTIONS_DEPLACEMENT
  }
}

export const pageTagOnUserScreenChange =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    const actionIsShowUser = action.type === showUiElement.type && action.payload === UI_ELEMENTS.USER
    if (actionIsShowUser || action.type === setUiUserScreen.type) {
      const store = getState()
      const isUIDisplayed = isUIElementDisplayed(UI_ELEMENTS.USER)(store)
      const userScreen = selectUserUIScreen(store)
      const pageTag = getPageTagForScreen(store, userScreen)
      if (isUIDisplayed && pageTag) {
        sendPageTag(resolvePageTag({ pageTag, store }))
      }
    }

    return r
  }

export default [pageTagOnUserScreenChange]
