import React from 'react'
import { useSelector } from 'react-redux'
import { selectFirstAddress } from '../../../store/address/address.selectors'
import { TAG_CATEGORY_POPIN } from '../../../store/analytics/analytics.actionTypes'
import { navigateToSearchBySubcategory } from '../../../store/history/history.actions'
import { selectIsFrenchSite } from '../../../store/navigation/navigation.selectors'
import T from '../../i18n/T'
import SVGIcon from '../../svg/SVGIcon'
import { useAction } from '../../utils/react-redux'
import { ADDRESS_BIG_MARKER_HEIGHT, ADDRESS_BIG_MARKER_WIDTH } from '../markers/MarkerConstants'
import s from './PopupAddress.less'

import GoToItineraryButton from '../../genericComponents/GoToItineraryButton'
import TagActionButton from '../../genericComponents/TagActionButton'
import useEventCallback from '../../utils/useEventCallback'

export const POPUP_ADDRESS_OFFSET = {
  bottom: [0, -ADDRESS_BIG_MARKER_HEIGHT],
  left: [ADDRESS_BIG_MARKER_WIDTH / 2, -ADDRESS_BIG_MARKER_HEIGHT / 2],
  right: [-ADDRESS_BIG_MARKER_WIDTH / 2, -ADDRESS_BIG_MARKER_HEIGHT / 2],
  'bottom-left': [0, -ADDRESS_BIG_MARKER_HEIGHT],
  'bottom-right': [0, -ADDRESS_BIG_MARKER_HEIGHT]
}

const listPoi = [
  { icon: 'hotel-hebergement', subcategory: 'hotel', type: 'hotel', text: 'map.find_hotel' },
  { icon: 'resto-bar', subcategory: 'restaurant', type: 'restaurant', text: 'map.find_restaurant' },
  { icon: 'parking', subcategory: 'parking', type: 'parking', text: 'map.find_parking' }
]

export const PopupAddress = () => {
  const address = useSelector(selectFirstAddress)
  const isFrFR = useSelector(selectIsFrenchSite)
  const execNavigateToSearchBySubcategory = useAction(navigateToSearchBySubcategory)

  const handleLinkPoiClick = useEventCallback(
    (e, { subcategory }) => {
      execNavigateToSearchBySubcategory(subcategory)
    },
    [execNavigateToSearchBySubcategory]
  )

  const tagProps = {
    tagInfo: {
      ancrage: 'PopinAdresse'
    }
  }
  return (
    <div>
      <div className={s.address}>
        <div className={s.street}>{address?.split_label?.[0]}</div>
        <div className={s.city}>{address?.split_label?.[1]}</div>
      </div>
      <div className={s.buttonWrapper}>
        <GoToItineraryButton location={address} tagProps={tagProps} />
      </div>

      {isFrFR && (
        <div>
          {listPoi.map(poiPage => (
            <TagActionButton
              key={`popupAddress.listPoi.${poiPage.type}`}
              className={s.linkPoiItem}
              onClick={e => handleLinkPoiClick(e, poiPage)}
              deferClick
              type={TAG_CATEGORY_POPIN}
              tagInfo={{ catId: poiPage.category, appId: poiPage.type }}
            >
              <SVGIcon icon={poiPage.icon} />
              <span className={s.linkPoiItemText}>
                <T id={poiPage.text} />
              </span>
            </TagActionButton>
          ))}
        </div>
      )}
    </div>
  )
}

export default PopupAddress
